#------------------------------------------------------
# responsive text
#------------------------------------------------------

WIDTH = 320
FONT_SIZE = 16

resizeHandler = ->
  w = Math.max($(this).width() / WIDTH * FONT_SIZE, 10)
  $('html').css 'font-size': w + 'px'
  return

#------------------------------------------------------
# 各変数の定義
#------------------------------------------------------

current_scrollY = undefined
current_scrollsize = undefined
$overflow_contents = $('body')
$current_trigger = $('.menu-trigger')
$current_trigger_pos = undefined
$current_modal = $('.modalWrap')

#------------------------------------------------------
# ハンバーガーメニューを押した時の処理
#------------------------------------------------------

$current_trigger.on 'touchstart click', ->
  
  # ハンバーガーメニューを閉じるボタンに変形しモーダルを表示
  $(this).toggleClass 'active'
  $current_modal.toggleClass 'active'
  
  # ハンバーガーメニューに 'active' クラスが付いているかどうかの条件分岐
  if $(this).hasClass('active')
    
    # ハンバーガメニューの位置をスクロールバー分ずらす
    $(this).css { right: $current_trigger_pos + current_scrollsize }
    
    # 現在のスクロール位置を取得し 'body' の位置を固定
    current_scrollY = $(window).scrollTop()
    $overflow_contents.css
      position: 'fixed'
      width: '100%'
      top: -1 * current_scrollY

  else
    
    # ハンバーガメニューの位置を初期化
    $(this).css { right: '' }
    
    # 現在のスクロール位置を初期化
    $overflow_contents.attr style: ''
    $('html, body').prop scrollTop: current_scrollY
    
  false
  
#------------------------------------------------------
# ハンバーガーメニューの位置調整（初回読み込み＆リサイズ）
#------------------------------------------------------

$(window).on 'load resize', ->
  
  # 現在のスクロールバー込みの幅サイズを取得
  current_scrollsize = window.innerWidth - $(window).width()
  
  # 現在のハンバーガメニューの位置（px）を取得
  $current_trigger_pos = parseInt $current_trigger.css('right').replace('px', '')
  
  resizeHandler()

  return